<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_moveLocationList" />
      <v-form v-model="isInputCheck" ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.supplierRequired]"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="moveLocDate"
                class="txt-single date-style"
                :label="$t('label.lbl_moveLocDate')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="moveLocDateCal" @input="dateMenu = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="preDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 品番・品名 -->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="ProdNameSelected"
                :items="prodNameList"
                @change="changeProductNm"
                :search-input.sync="search"
                :label="$t('label.lbl_productCnCd')"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!--品質区分-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="prodStatus"
                :items="prodStatusList"
                :label="$t('label.lbl_arrivalStatus')"
                class="txt-single"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="item-spacer">&nbsp;</span>

            <!--ロット-->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="lotNo"
                maxlength="20"
                :label="$t('label.lbl_lot')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- S/N -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="serial"
                maxlength="32"
                :label="$t('label.lbl_serial')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!-- 移動前倉庫-->
              <div class="search-autocomplete-warehouse first-search-item" style="float: left">
                <v-autocomplete
                  dense
                  v-model="preWarehouseSelected"
                  :items="warehouseList"
                  :label="$t('label.lbl_preWarehouse')"
                  class="txt-single"
                  persistent-hint
                  @change="getLocationBefore()"
                >
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span>
                  </template>
                </v-autocomplete>
              </div>
              <!-- 空白 -->
              <span class="item-spacer">&nbsp;</span>

              <!-- 移動前ロケ-->
              <div class="search-autocomplete-loc first-search-item" style="float: left">
                <v-autocomplete
                  dense
                  v-model="preLocSelected"
                  :items="prelocationList"
                  :label="$t('label.lbl_preLocation')"
                  @change="(event) => changePreLocation(event)"
                  class="txt-single"
                  persistent-hint
                  :search-input.sync="locaPreSearch"
                >
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span>
                  </template>
                </v-autocomplete>
              </div>
              <span class="item-spacer">&nbsp;</span>

              <!-- 移動後倉庫-->
              <div class="search-autocomplete-warehouse first-search-item" style="float: left">
                <v-autocomplete
                  dense
                  v-model="warehouseSelected"
                  :items="warehouseList"
                  :label="$t('label.lbl_warehouse')"
                  class="txt-single"
                  persistent-hint
                >
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span>
                  </template>
                </v-autocomplete>
              </div>
              <span class="item-spacer">&nbsp;</span>

              <!-- 移動後ロケ-->
              <div class="search-autocomplete-loc first-search-item" style="float: left">
                <v-autocomplete
                  dense
                  v-model="locationSelected"
                  :items="locationList"
                  :label="$t('label.lbl_location')"
                  @change="(event) => changeLocation(event)"
                  class="txt-single"
                  persistent-hint
                  :search-input.sync="locaSearch"
                >
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span>
                  </template>
                </v-autocomplete>
              </div>
              <div class="item-spacer" style="float: left">&nbsp;</div>

              <!-- 処理区分-->
              <div class="search-autocomplete-processType" style="float: left">
                <v-autocomplete
                  dense
                  v-model="processDivision"
                  :items="processDivList"
                  :label="$t('label.lbl_processingdivision')"
                  class="txt-single"
                  persistent-hint
                >
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span>
                  </template>
                </v-autocomplete>
              </div>
              <div class="item-spacer" style="float: left">&nbsp;</div>

              <!-- 有償/無償-->
              <div style="float: left">
                <v-btn-toggle v-model="isPaid" color="blue darken-2" id="btn-toggle-btn-padding">
                  <v-btn
                    class="paid-btn"
                    v-for="item in freeCostDivList"
                    :key="item.value"
                    :value="item.value"
                    small
                  >
                    {{ item.text }}</v-btn
                  >
                </v-btn-toggle>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 期限日 -->
              <div class="serch-textbox-dueDate" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="dueDate"
                  maxlength="8"
                  :label="$t('label.lbl_dueDate')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                />
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 付属キー１ -->
              <div class="serch-textbox-arrivalNo" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="lotSubkey1"
                  maxlength="20"
                  :label="$t('label.lbl_lotSubkey1')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                />
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 付属キー２ -->
              <div class="serch-textbox-arrivalNo" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="lotSubkey2"
                  maxlength="20"
                  :label="$t('label.lbl_lotSubkey2')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                />
              </div>
            </div>
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン-->
                <v-btn color="primary" class="api-btn" @click="searchBtn()">
                  {{ $t("btn.btn_search") }}
                </v-btn>
              </div>

              <!--登録ボタン領域-->
              <div class="btn-area">
                <!--実績登録処理ボタン-->
                <v-btn
                  v-if="registRole == 'true'"
                  color="primary"
                  id="btn-search"
                  class="api-btn"
                  @click="achieveAdd()"
                  >{{ $t("btn.btn_achieveAdd") }}</v-btn
                >
              </div>
            </div>
          </v-row>

          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :server-items-length="itemsPerPage"
            disable-filtering
            :options.sync="sortOptions"
            :hide-default-footer="true"
            height="530px"
          >
            <!-- ヘッダーロット -->
            <template v-slot:[`header.lot`]="{ header }">
              <span>
                <div>{{ header.text }}</div>
                <span>
                  {{
                    $t("label.lbl_dueDate") +
                    "／" +
                    $t("label.lbl_lotSubkey1") +
                    "／" +
                    $t("label.lbl_lotSubkey2")
                  }}
                </span>
              </span>
            </template>
            <!-- 一覧チェックボックス -->
            <template v-slot:[`item.check`]="{ item }">
              <input
                type="checkbox"
                v-model="item.check"
                :value="listCheckbox"
                style="transform: scale(2); margin-left: 5px"
              />
            </template>
            <!-- ヘッダーチェックボックス -->
            <template v-slot:[`header.check`]="{ header }">
              <input
                type="checkbox"
                :checked="isAllSelected"
                :value="header.check"
                @click="selectAllCheck"
                style="transform: scale(2); margin-left: 5px"
              />
            </template>
            <!-- 品番・品名 -->
            <template v-slot:[`item.productCnCd`]="{ item }">
              <div v-if="item.productName.length > 20">
                <div v-if="item.incidental">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                        {{ item.productCnCd }}<br />
                        {{ item.incidental }}<br />
                        {{ item.productName.substring(0, 20) + "..." }}
                      </div>
                    </template>
                    <div>{{ item.productCnCd }}</div>
                    <div>{{ item.incidental }}</div>
                    <div>{{ item.productName }}</div>
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                        {{ item.productCnCd }}<br />
                        {{ item.productName.substring(0, 20) + "..." }}
                      </div>
                    </template>
                    <div>{{ item.productCnCd }}</div>
                    <div>{{ item.productName }}</div>
                  </v-tooltip>
                </div>
              </div>
              <div v-else>
                <div v-if="item.incidental">
                  {{ item.productCnCd }}<br />
                  {{ item.incidental }}<br />
                  {{ item.productName }}
                </div>
                <div v-else>
                  {{ item.productCnCd }}<br />
                  {{ item.productName }}
                </div>
              </div>
            </template>

            <!-- ロット/S/N -->
            <template v-slot:[`item.lot`]="{ item }">
              <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="width: 345px" v-bind="attrs" v-on="on">
                      {{ item.lot }}<br />
                      {{ item.dueDate }}
                    </div>
                  </template>
                  <div>{{ item.lot }}<br />{{ item.dueDate }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                {{ item.lot }}<br />
                {{ item.dueDate }}
              </div>
            </template>

            <!-- 詳細ボタン -->
            <template v-slot:[`item.location`]="{ item }">
              <v-btn @click="updateInstructions(item)" small text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          </div>
        </v-container>
      </v-form>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="true"
        :thirdPageFlag="false"
      />
      <!-- アラートダイアログ -->
      <v-dialog v-model="alertDialogFlg" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ alertMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okClicked"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
export default {
  name: appConfig.SCREEN_ID.P_MOV_003,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    pListPopup: false,
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
    },
    locaSearch: "",
    locaPreSearch: "",
    // 入力チェック結果フラグ
    isInputCheck: true,
    // 総件数
    totalCount: 0,
    search: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // 実績登録画面に渡す選択した品番リスト
    checkedList: [],
    // 修正削除画面に渡すid
    inListSid: "",
    // 有償/無償
    isPaid: "02",
    freeCostDivList: [],
    // 取引先
    suppliersSelected: "",
    // 移動前倉庫
    preWarehouseSelected: "",
    // 移動後倉庫
    warehouseSelected: "",
    // 倉庫リスト
    warehouseList: [],
    // 移動前ロケ
    preLocSelected: "",
    // 移動後ロケ
    locationSelected: "",
    // 移動前ロケーションリスト
    prelocationList: [],
    prelocationListCount: 0,
    // 移動後ロケーションリスト
    locationList: [],
    locationListCount: 0,
    // 品質区分
    prodStatus: "",
    prodStatusList: [],
    qualityDiv: "",
    // ロット
    lotNo: "",
    // S/N
    serial: "",
    //期限日
    dueDate: "",
    //付属キー１
    lotSubkey1: "",
    //付属キー２
    lotSubkey2: "",
    // 処理区分
    processDivList: [],
    processDivision: "",
    completeFlg: "",
    // 選択された品番/品名
    ProdNameSelected: "",
    prodNameList: [],
    prodNameListCount: 0,
    lot: "",
    // メニュー
    openMenu: null,
    // はい替え指示日
    moveLocDate: "",
    moveLocDateCal: "",
    // 入荷予定日付キャレンダー
    dateMenu: false,
    // アラートダイアログフラグ
    alertDialogFlg: false,
    // ダイアログ表示内容
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    alertMessage: "",
    // ソート
    sortItem: "from_warehouse_sid asc, from_location_sid asc",
    sortOptions: {},
    // 実績登録実行権限
    registRole: "true",
    // 遷移フラグ
    transitionFlg: false,
    // ヘッダ
    headerItems: [
      // チェックボックス
      {
        text: "選択",
        value: "check",
        width: "2%",
        align: "left",
        sortable: false,
      },
      // 指示No
      {
        text: i18n.tc("label.lbl_instNo"),
        value: "instNo",
        width: "8%",
        align: "left",
        sortable: true,
      },
      // 移動前倉庫/ロケ
      {
        text: i18n.tc("label.lbl_preWarehouse") + "/" + i18n.tc("label.lbl_location"),
        value: "locations",
        width: "15%",
        align: "left",
        sortable: true,
      },
      // 移動後倉庫/ロケ
      {
        text: i18n.tc("label.lbl_postWarehouse") + "/" + i18n.tc("label.lbl_location"),
        value: "postWareLoc",
        width: "15%",
        align: "left",
        sortable: true,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "15%",
        align: "left",
        sortable: true,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "19%",
        align: "left",
        sortable: true,
      },

      // 品質区分
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "8%",
        align: "left",
        sortable: true,
      },
      // 有償/無償
      {
        text: i18n.tc("label.lbl_isPaid"),
        value: "isPaid",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 移動数
      {
        text: "移動数",
        value: "quantity",
        width: "6%",
        align: "right",
        sortable: true,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "location",
        align: "center",
        width: "5%",
        sortable: false,
      },
    ],
    detailDialogData: {},
    inputList: [],
    supplierList: [],
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNumber"),
    },
    //検索条件保持
    searchMoveLocDate: "",
    searchProdNameSelected: "",
    searchProdNameList: [],
    searchProdStatus: "",
    searchLotNo: "",
    searchSerial: "",
    searchDueDate: "",
    searchLotSubkey1: "",
    searchLotSubkey2: "",
    searchPreWarehouseSelected: "",
    searchPreLocSelected: "",
    searchPreLocationList: [],
    searchWarehouseSelected: "",
    searchLocationSelected: "",
    searchLocationList: [],
    searchProcessDivision: "",
    searchIsPaid: "",
  }),
  methods: {
    openPopup() {
      this.pListPopup = true;
    },
    // ページング
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.searchBtn();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.searchBtn();
    },

    // 初期化
    init() {
      this.moveLocDate = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.moveLocDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      if (this.$route.params.name == appConfig.SCREEN_ID.P_MOV_002) {
        // 検索条件をセット
        this.suppliersSelected = this.$route.params.suppliersSelected;
        this.ProdNameSelected = this.$route.params.itemCd ? this.$route.params.itemCd : "";
        this.prodNameList = this.$route.params.prodNameList;
        this.lotNo = this.$route.params.lotNo;
        this.serial = this.$route.params.serial;
        this.dueDate = this.$route.params.dueDate;
        this.lotSubkey1 = this.$route.params.lotSubkey1;
        this.lotSubkey2 = this.$route.params.lotSubkey2;
        this.preWarehouseSelected = this.$route.params.preWarehouse;
        this.warehouseSelected = this.$route.params.warehouse;
        this.preLocSelected = this.$route.params.preLocation;
        this.prelocationList = this.$route.params.prelocationList;
        this.locationSelected = this.$route.params.location;
        this.locationList = this.$route.params.locationList;
        this.prodStatus = this.$route.params.status;
        this.processDivision = this.$route.params.processDivision;
        this.moveLocDate = this.$route.params.moveDate;
        this.moveLocDateCal = dateTimeHelper.dateCalc(this.$route.params.moveDate);
        this.isPaid = this.$route.params.isPaid;
        this.transitionFlg = true;
        this.searchBtn();

        // はい替え実績登録画面からの遷移
      } else if (this.$route.params.name == "MovLocationAchieveAddIns") {
        // 検索条件をセット
        this.suppliersSelected = this.$route.params.suppliersSelected;
        this.ProdNameSelected = this.$route.params.prodNameSelected
          ? this.$route.params.prodNameSelected
          : "";
        this.prodNameList = this.$route.params.prodNameList;
        this.lotNo = this.$route.params.lotNo1;
        this.serial = this.$route.params.serial;
        this.dueDate = this.$route.params.dueDate;
        this.lotSubkey1 = this.$route.params.lotSubkey1;
        this.lotSubkey2 = this.$route.params.lotSubkey2;
        this.preWarehouseSelected = this.$route.params.preWarehouseSelected;
        this.warehouseSelected = this.$route.params.warehouseSelected;
        this.preLocSelected = this.$route.params.preLocSelected;
        this.prelocationList = this.$route.params.prelocationList;
        this.locationSelected = this.$route.params.locationSelected;
        this.locationList = this.$route.params.locationList;
        this.prodStatus = this.$route.params.prodStatus;
        this.processDivision = this.$route.params.processDivision;
        this.moveLocDate = this.$route.params.moveLocDate;
        this.moveLocDateCal = dateTimeHelper.dateCalc(this.$route.params.moveLocDate);
        this.isPaid = this.$route.params.isPaid;
        this.transitionFlg = true;
        this.searchBtn();
      }

      // 実績登録権限を取得
      this.registRole = sessionStorage.getItem("P-MOV-004");
      this.getInitData();
    },
    // 初期データ取得
    getInitData() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 処理区分
      const processDivList = getParameter.getCodeMst(appConfig.CODETYPE.PROCESS_DIV);
      // 品質区分
      const prodStatusList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 有償無償区分
      const freeCostDivList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(null)
      );
      const warehouses = getParameter.getWarehouseBiz();
      Promise.all([processDivList, prodStatusList, freeCostDivList, clientList, warehouses])
        .then((result) => {
          // 画面の初期値を設定します。
          this.processDivList = result[0];
          this.prodStatusList = result[1];
          this.freeCostDivList = result[2];
          this.supplierList = result[3];
          this.warehouseList = result[4];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    // 全選択チェック
    selectAllCheck() {
      // チェックボックス選択時
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;
        }
        this.isAllSelected = false;
      }
    },
    preDate() {
      if (this.moveLocDateCal == null) {
        return;
      }
      let date = new Date(this.moveLocDateCal);
      this.moveLocDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    nextDate() {
      if (this.moveLocDateCal == null) {
        return;
      }
      let date = new Date(this.moveLocDateCal);
      this.moveLocDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.moveLocDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.moveLocDateCal = null;
      }
    },

    /**
     * 取引先コードリストボックスセット処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    getClientName() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.inputList[0].clientSid) {
          return this.supplierList[i].text;
        }
      }
    },

    // 実績登録押下時
    achieveAdd() {
      if (this.$refs.form.validate()) {
        // 品番
        let moveLocItemCdList = [];
        // 品質区分
        let moveLocStatusList = [];
        // ロット
        let moveLocLotList = [];
        // 移動前ロケ
        let moveLocFromLocList = [];
        // 移動前倉庫
        let moveLocFromWarehouseList = [];
        // 移動後ロケ
        let moveLocToLocList = [];
        // 移動後倉庫
        let moveLocToWarehouseList = [];
        // 有償無償
        let moveLocIsPaidList = [];

        // チェックした行
        var checkList = [];
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].check) {
            checkList.push(this.inputList[i]);
          }
        }

        for (let i = 0; i < checkList.length; i++) {
          moveLocItemCdList.push(checkList[i].productCnCdShow);
          moveLocStatusList.push(checkList[i].status);
          moveLocLotList.push(checkList[i].lotShow);
          moveLocFromLocList.push(checkList[i].locationSid);
          moveLocFromWarehouseList.push(checkList[i].warehouseSid);
          moveLocToLocList.push(checkList[i].postLocationSid);
          moveLocToWarehouseList.push(checkList[i].postWarehouseSid);
          moveLocIsPaidList.push(checkList[i].isPaid);
        }
        let MLICdList = moveLocItemCdList.filter(function (val, i, array) {
          return array.indexOf(val) === i;
        });
        let MLStatusList = moveLocStatusList.filter(function (val, i, array) {
          return array.indexOf(val) === i;
        });
        let MLLLotist = moveLocLotList.filter(function (val, i, array) {
          return array.indexOf(val) === i;
        });
        let MLFromLocList = moveLocFromLocList.filter(function (val, i, array) {
          return array.indexOf(val) === i;
        });
        let MLFromWareList = moveLocFromWarehouseList.filter(function (val, i, array) {
          return array.indexOf(val) === i;
        });
        let MLToLocList = moveLocToLocList.filter(function (val, i, array) {
          return array.indexOf(val) === i;
        });
        let MLToWareList = moveLocToWarehouseList.filter(function (val, i, array) {
          return array.indexOf(val) === i;
        });
        let MLIsPaidList = moveLocIsPaidList.filter(function (val, i, array) {
          return array.indexOf(val) === i;
        });
        // 実績登録済みかチェック
        var achieveChekFlg = [];
        for (var t = 0; t < checkList.length; t++) {
          if (checkList[t].completeFlg == i18n.tc("label.lbl_processed")) {
            achieveChekFlg.push(checkList[t]);
          }
        }

        // １つもチェックされていない場合、画面遷移できない
        if (checkList.length == 0) {
          return;
          // 品番・品名、倉庫、ロケ、有償無償、品質区分が同一であれば遷移可能
        } else if (
          checkList.length !== 0 &&
          MLICdList.length == 1 &&
          MLStatusList.length == 1 &&
          MLLLotist.length == 1 &&
          MLFromLocList.length == 1 &&
          MLFromWareList.length == 1 &&
          MLToLocList.length == 1 &&
          MLToWareList.length == 1 &&
          MLIsPaidList.length == 1 &&
          achieveChekFlg.length == 0
        ) {
          // 画面遷移
          this.$router.push({
            name: appConfig.SCREEN_ID.P_MOV_005,
            params: {
              checkList: checkList,
              locaMoveSid: checkList[0].locaMoveSid,
              userSid: checkList[0].userSid,
              // 検索条件を渡す
              isPaid: checkList[0].isPaid,
              supplier: checkList[0].clientSid,
              clientName: this.getClientName(),
              locationSid: checkList[0].locationSid,
              warehouseSid: checkList[0].warehouseSid,
              itemSid: checkList[0].itemSid,
              itemCd: checkList[0].ProdNameSelected,
              lotNo: checkList[0].lot,
              qualityName: checkList[0].prodStatus,
              warehouse: checkList[0].warehouseSelected,
              location: checkList[0].locationSelected,
              quantity: checkList[0].moveQty,
              moveDate: checkList[0].yyyymmddHhmmss,
              prodNameSelected: this.searchProdNameSelected,
              lotNo1: this.searchLotNo,
              serial: this.searchSerial,
              dueDate: this.searchDueDate,
              lotSubkey1: this.searchLotSubkey1,
              lotSubkey2: this.searchLotSubkey2,
              preWarehouseSelected: this.searchPreWarehouseSelected,
              warehouseSelected: this.searchWarehouseSelected,
              preLocSelected: this.searchPreLocSelected,
              locationSelected: this.searchLocationSelected,
              prodStatus: this.searchProdStatus,
              processDivision: this.searchProcessDivision,
              moveLocDate: this.searchMoveLocDate,
              prodNameList: this.searchProdNameList,
              prelocationList: this.searchPreLocationList,
              locationList: this.searchLocationList,
              searchIsPaid: this.searchIsPaid,
              name: "MovLocationAchieveAddIns",
            },
          });
          // 品番・品名、倉庫、ロケ、有償無償、品質区分が同一ではない場合遷移不可
        } else if (
          checkList.length !== 0 &&
          !(
            MLICdList.length == 1 &&
            MLStatusList.length == 1 &&
            MLLLotist.length == 1 &&
            MLFromLocList.length == 1 &&
            MLFromWareList.length == 1 &&
            MLToLocList.length == 1 &&
            MLToWareList.length == 1 &&
            MLIsPaidList.length == 1
          )
        ) {
          this.alertDialogFlg = true;
          this.alertMessage = i18n.tc("check.chk_achieve_duplicate");
          // 実績登録済みの場合、再登録不可
        } else if (achieveChekFlg.length !== 0) {
          this.alertDialogFlg = true;
          this.alertMessage = i18n.tc("check.chk_achieve_already");
        }
      }
    },
    // ダイアログ閉じる
    okClicked() {
      this.alertDialogFlg = false;
    },

    //検索ボタン押下
    searchBtn() {
      const config = this.$httpClient.createGetApiRequestConfig();

      // 入力チェック結果フラグ
      let inputCheckResult = false;

      /**
       * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
       * エラーがでるので回避するため、このようなコードとなっている。
       */
      if (this.$route.params.suppliersSelected) {
        // 登録画面からの遷移の場合、入力チェックは実施しない。
        inputCheckResult = true;
      } else {
        // 上記外の場合はチェックを行う。
        inputCheckResult = this.$refs.form.validate();
      }

      // 入力チェックを実施
      if (inputCheckResult) {
        this.loadingCounter = 1;
        // ページャー処理
        if (this.page >= 2 && this.inputList.length == 0) {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          config.params.reqComPageIndex = this.page;
        }
        // ページング
        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;
        // ソート
        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
        }

        config.params.officeSid = sessionStorage.getItem("sales_office_sid");
        config.params.clientSid = this.suppliersSelected;
        config.params.yyyymmddHhmmss = dateTimeHelper.convertUTC(
          this.moveLocDate + " 00:00:00.000"
        );

        // 品番選択時
        if (this.ProdNameSelected) {
          config.params.itemStandardSid = this.ProdNameSelected;
        }
        // 品質区分選択時
        if (this.prodStatus) {
          config.params.qualityDiv = this.prodStatus;
        }
        // ロット入力時
        if (this.lotNo) {
          config.params.lotNo = this.lotNo;
        }
        // S/N入力時
        if (this.serial) {
          config.params.serial = this.serial;
        }
        // 期限日入力時
        if (this.dueDate) {
          config.params.dueDate = this.dueDate;
        }
        // 付属キー１入力時
        if (this.lotSubkey1) {
          config.params.lotSubkey1 = this.lotSubkey1;
        }
        // 付属キー２入力時
        if (this.lotSubkey2) {
          config.params.lotSubkey2 = this.lotSubkey2;
        }
        // 移動前倉庫選択時
        if (this.preWarehouseSelected) {
          config.params.fromWarehouseSid = this.preWarehouseSelected;
        }
        // 移動前ロケ選択時
        if (this.preLocSelected) {
          config.params.fromLocationSid = this.preLocSelected;
        }
        // 移動後倉庫選択時
        if (this.warehouseSelected) {
          config.params.toWarehouseSid = this.warehouseSelected;
        }
        // 移行後ロケ選択時
        if (this.locationSelected) {
          config.params.toLocationSid = this.locationSelected;
        }
        // 処理区分選択時
        if (this.processDivision) {
          if (this.processDivision == "01") {
            // 未処理
            config.params.completeFlg = "0";
          } else if (this.processDivision == "02") {
            // 処理済
            config.params.completeFlg = "2";
          }
        }
        // 有償無償
        if (this.isPaid) {
          config.params.freeCostDiv = this.isPaid;
        }
        // 検索値保持
        this.searchMoveLocDate = this.moveLocDate;
        this.searchProdNameSelected = this.ProdNameSelected;
        this.searchProdNameList =
          this.ProdNameSelected == "" || this.ProdNameSelected == null
            ? []
            : new Array(...this.prodNameList);
        this.searchProdStatus = this.prodStatus;
        this.searchLotNo = this.lotNo;
        this.searchSerial = this.serial;
        this.searchDueDate = this.dueDate;
        this.searchLotSubkey1 = this.lotSubkey1;
        this.searchLotSubkey2 = this.lotSubkey2;
        this.searchPreWarehouseSelected = this.preWarehouseSelected;
        this.searchPreLocSelected = this.preLocSelected;
        this.searchPreLocationList =
          this.preLocSelected == "" || this.preLocSelected == null
            ? []
            : new Array(...this.prelocationList);
        this.searchWarehouseSelected = this.warehouseSelected;
        this.searchLocationSelected = this.locationSelected;
        this.searchLocationList =
          this.locationSelected == "" || this.locationSelected == null
            ? []
            : new Array(...this.locationList);
        this.searchProcessDivision = this.processDivision;
        this.searchIsPaid = this.isPaid;

        return new Promise((resolve, reject) => {
          this.$httpClient
            .secureGet(appConfig.API_URL.BIZ_LOCATION_MOVE, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                // ページング：総ページ数
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                // ページング：総件数
                this.totalCount = jsonData.resCom.resComPagination.totalRecord;

                // 返却するリスト
                const list = [];
                jsonData.resIdv.locationMove.forEach((row) => {
                  // 処理区分
                  if (row.completeFlg == "0") {
                    this.completeFlg = i18n.tc("label.lbl_unprocessed");
                  } else {
                    this.completeFlg = i18n.tc("label.lbl_processed");
                  }
                  let lotNoObj = commonUtil.convertLotNo(row.lotNo);
                  list.push({
                    instNo: row.locaMoveSid, // 指示No
                    warehouseSid: row.fromWarehouseSid, // 移動前倉庫SID
                    locationSid: row.fromLocationSid, //移動前ロケSID
                    locations: `${row.fromWarehouseName}/${row.fromLocationNo}`, //移動前倉庫ロケ
                    postWareLoc: `${row.toWarehouseName}/${row.toLocationNo}`, //移動後倉庫ロケ
                    locationNo: row.fromLocationNo, //移動前ロケNo
                    postLocationNo: row.toLocationNo, //移動後ロケNo
                    warehouseName: row.fromWarehouseName, //移動前倉庫名
                    postWarehouseName: row.toWarehouseName, //移動後倉庫名
                    postWarehouseSid: row.toWarehouseSid, //移動後倉庫SID
                    postLocationSid: row.toLocationSid, //移動後ロケSID
                    productCnCdShow: row.itemCd, //品番
                    productCnCd: row.itemCd.substring(0, 50).trimRight(), //品番
                    incidental: row.itemCd.slice(-50).trimRight(), //付帯品番
                    productName: row.itemName, //品名
                    itemSid: row.itemStandardSid, //商品SID
                    lotShow: row.lotNo, //ロットNo
                    lot: lotNoObj.lot, //ロットNo + S/N
                    dueDate: lotNoObj.dueDate, //期限日 + 付属キー１ + 付属キー２
                    status: row.qualityName, //品質区分
                    qualityDiv: row.qualityDiv, //品質区分(コード)
                    isPaid: row.freeCostName, //有償無償
                    freeCostDiv: row.freeCostDiv,
                    quantity: commonUtil.formatToCurrency(Number(row.stock)), //移動数
                    locaMoveSid: row.locaMoveSid, //指示SID,
                    updateDatetime: row.updateDatetime, //更新日時
                    yyyymmddHhmmss: dateTimeHelper.convertUTC2JST(row.yyyymmddHhmmss), //検索日
                    completeFlg: this.completeFlg,
                    systemStock: commonUtil.formatToCurrency(Number(row.systemStock)), //在庫
                    clientSid: row.clientSid, // 取引先SID
                  });
                });
                this.inputList = list;
              } else {
                // エラーメッセージをpopupのmessageに格納
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
              }
              resolve();
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      } else {
        // 入力チェックエラーの場合
        this.isInputCheck = false;
      }
    },
    // 詳細ボタン押下後、はい替え指示登録画面へ遷移
    updateInstructions(item) {
      if (this.$refs.form.validate()) {
        // 移動数が次画面ではmoveQtyの為
        item.moveQty = item.quantity;
        var checkedList = [];
        checkedList.push(item);

        // 登録画面に渡すために取引先名を取得する
        var clientName = "";
        this.supplierList.forEach((row) => {
          if (row.value == checkedList[0].clientSid) {
            clientName = row.name;
          }
        });
        // 画面遷移
        this.$router.push({
          name: appConfig.SCREEN_ID.P_MOV_002,
          params: {
            checkList: checkedList,
            // 下記検索条件も渡す
            supplier: checkedList[0].clientSid,
            moveDate: this.searchMoveLocDate,
            itemCd: this.searchProdNameSelected,
            status: this.searchProdStatus,
            qualityDiv: item.qualityDiv,
            lotNo: this.searchLotNo,
            serial: this.searchSerial,
            dueDate: this.searchDueDate,
            lotSubkey1: this.searchLotSubkey1,
            lotSubkey2: this.searchLotSubkey2,
            preWarehouse: this.searchPreWarehouseSelected,
            preLocation: this.searchPreLocSelected,
            warehouse: this.searchWarehouseSelected,
            location: this.searchLocationSelected,
            isPaid: item.freeCostDiv,
            searchIsPaid: this.searchIsPaid,
            processDivision: this.searchProcessDivision,
            clientName: clientName,
            prodNameList: this.searchProdNameList,
            prelocationList: this.searchPreLocationList,
            locationList: this.searchLocationList,
            name: "MovLocationInstList",
          },
        });
      }
    },

    /**
     * プルダウンチェンジイベント(前ロケ)
     */
    changePreLocation(value) {
      // 既存品番/品名を選択時
      let val = this.prelocationList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.locaPreSearch = val.text;
      }
      this.prelocationList = this.prelocationList.filter((v) => v.value == value);
    },

    /**
     * プルダウンチェンジイベント(後ロケ)
     */
    changeLocation(value) {
      // 既存品番/品名を選択時
      let val = this.locationList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        // this.locationHint = val.text;
        this.locaSearch = val.text;
      }
      this.locationList = this.locationList.filter((v) => v.value == value);
    },

    /**
     * プルダウンチェンジイベント
     */
    changeProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.prodNameList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
      }
      this.prodNameList = this.prodNameList.filter((v) => v.value == value);
    },

    // 移動前ロケを取得するメソッド
    getLocationBefore() {
      // 画面遷移判定
      if (this.$route.params.preLocation) {
        // 登録画面から遷移する場合は、引き継いだ値を設定する。
        this.preWarehouseSelected = this.$route.params.preLocation;
      } else {
        // NOP
      }
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        //this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    moveLocDateCal(val) {
      this.moveLocDate = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.searchBtn();
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MOV_003_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.searchBtn();
        }
      },
      deep: true,
    },
    search(val) {
      if (
        this.$route.params.name == appConfig.SCREEN_ID.P_MOV_002 ||
        this.$route.params.name == "MovLocationAchieveAddIns"
      ) {
        if (this.prodNameListCount < 2) {
          this.prodNameList = this.$route.params.prodNameList;
          this.prodNameListCount++;
          return;
        }
      }
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // 遷移後
        if (this.transitionFlg) {
          this.transitionFlg = false;
          return;
        }

        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.prodNameList = list.concat(result[0]);
              this.prodNameList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.prodNameList.length = 0;
          this.prodNameList = [];
        }
      }
    },
    locaPreSearch(val) {
      if (
        this.$route.params.name == appConfig.SCREEN_ID.P_MOV_002 ||
        this.$route.params.name == "MovLocationAchieveAddIns"
      ) {
        if (this.prelocationListCount < 1) {
          this.prelocationList = this.$route.params.prelocationList;
          this.prelocationListCount++;
          return;
        }
      }
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }

        // どちらかの倉庫が選択されていない場合、処理しない
        if (this.preWarehouseSelected == null || this.preWarehouseSelected == "") {
          return;
        }

        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          // ローディング画面表示ON
          this.loadingCounter = 1;

          const config = this.$httpClient.createGetApiRequestConfig();
          config.params.locationNo = val;
          config.params.officeSid = sessionStorage.getItem("sales_office_sid");
          // "0"=共通の基準日時で有効期間内データを取得 "1"=すべて取得
          config.params.searchCategoryDiv = "0";
          // 処理区分
          config.params.processDiv = "01";

          // 前倉庫が選択されていれば倉庫を設定する。
          if (this.preWarehouseSelected) {
            config.params.warehouseSid = this.preWarehouseSelected;
          }

          // console.debug("locaPreSearch(val) Config", config);

          return new Promise((resolve, reject) => {
            this.$httpClient
              .secureGet(appConfig.API_URL.MST_LOCATIONBIZ, config)
              .then((response) => {
                // console.debug("locaPreSearch(val) Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                  const list = [{ text: "", value: "" }];
                  jsonData.resIdv.locations.forEach((row) => {
                    list.push({
                      text: row.locationNo,
                      value: row.locationSid,
                      name: row.locationNo,
                    });
                  });

                  this.prelocationList = list;
                } else {
                  // エラーメッセージをpopupのmessageに格納
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                }
                resolve();
              })
              .catch((resolve) => {
                console.error("locaPreSearch(val) Resolve", resolve);
                this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject(resolve);
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        } else {
          // 移動前の場合、移動前のロケリストに設定する。
          this.prelocationList.length = 0;
          this.prelocationList = [];
        }
      }
    },
    locaSearch(val) {
      if (
        this.$route.params.name == appConfig.SCREEN_ID.P_MOV_002 ||
        this.$route.params.name == "MovLocationAchieveAddIns"
      ) {
        if (this.locationListCount < 1) {
          this.locationList = this.$route.params.locationList;
          this.locationListCount++;
          return;
        }
      }
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }

        // どちらかの倉庫が選択されていない場合、処理しない
        if (this.warehouseSelected == null || this.warehouseSelected == "") {
          return;
        }

        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          // ローディング画面表示ON
          this.loadingCounter = 1;

          const config = this.$httpClient.createGetApiRequestConfig();
          config.params.locationNo = val;
          config.params.officeSid = sessionStorage.getItem("sales_office_sid");
          // "0"=共通の基準日時で有効期間内データを取得 "1"=すべて取得
          config.params.searchCategoryDiv = "0";
          // 処理区分
          config.params.processDiv = "01";
          if (this.warehouseSelected) {
            // 後倉庫が選択されていれば、基準日設定する。
            config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.moveLocDate);
          }

          // console.debug("locaSearch(val) Config", config);

          return new Promise((resolve, reject) => {
            this.$httpClient
              .secureGet(appConfig.API_URL.MST_LOCATIONBIZ, config)
              .then((response) => {
                // console.debug("locaSearch(val) Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                  const list = [{ text: "", value: "" }];
                  jsonData.resIdv.locations.forEach((row) => {
                    list.push({
                      text: row.locationNo,
                      value: row.locationSid,
                      name: row.locationNo,
                    });
                  });

                  this.locationList = list;
                } else {
                  // エラーメッセージをpopupのmessageに格納
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                }
                resolve();
              })
              .catch((resolve) => {
                console.error("locaSearch(val) Resolve", resolve);
                this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject(resolve);
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        } else {
          this.locationList.length = 0;
          this.locationList = [];
        }
      }
    },
    suppliersSelected() {
      if (this.transitionFlg) {
        // 遷移後
        return;
      } else {
        //品番/品名クリア
        this.prodNameList = [];
        this.ProdNameSelected = "";
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.overflow {
  overflow: hidden;
}

.toatlNum {
  margin-left: 10px;
}

.txt-center {
  margin: auto;
}

.link {
  text-decoration: none;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 8px;
}
</style>
